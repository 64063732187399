import jwt from 'jsonwebtoken';
import { get } from 'lodash';
import { createBrowserHistory } from "history";

export const ONE_OFF = [
    {
        value: 0,
        label: "Please select a One Off rate"
    },
    {
        value: 1,
        label: "£250 - £1,000"
    },
    {
        value: 2,
        label: "£1,000 - £2,000"
    },
    {
        value: 3,
        label: "£2,000 - £5,000"
    },
    {
        value: 4,
        label: "£5,000 - £10,000+"
    },
]

export const MONTHLY = [
    {
        value: 0,
        label: "Please select a Monthly rate"
    },
    {
        value: 1,
        label: "£250 - £1,000"
    },
    {
        value: 2,
        label: "£1,000 - £2,000"
    },
    {
        value: 3,
        label: "£2,000 - £5,000"
    },
    {
        value: 4,
        label: "£5,000 - £10,000+"
    },
]

export const COMPANY_SIZE = [
    {
        value: 1,
        label: "1 - 10"
    },
    {
        value: 2,
        label: "11 - 50"
    },
    {
        value: 3,
        label: "51 - 100"
    },
    {
        value: 4,
        label: "101 - 200"
    },
    {
        value: 5,
        label: "201 - 500"
    },
    {
        value: 6,
        label: "500 +"
    },
]

export const COMPANY_SCALE = [
    {
        value: 1,
        label: "Local"
    },
    {
        value: 2,
        label: "National"
    },
    {
        value: 3,
        label: "Global"
    },
]

export const homePageIcons = [
    {
        title: 'Web Design/Dev',
        image: require('./assets/images/HomeIcons/1.png'),
        id: 14
    },
    {
        title: 'Digital Marketing',
        image: require('./assets/images/HomeIcons/2.png'),
        id: 10
    },
    {
        title: 'Social Media Marketing',
        image: require('./assets/images/HomeIcons/3.png'),
        id: 11
    },
    {
        title: 'Social Media Management',
        image: require('./assets/images/HomeIcons/4.png'),
        id: 12
    },
    {
        title: 'SEO',
        image: require('./assets/images/HomeIcons/5.png'),
        id: 15
    },
    {
        title: 'Marketing Content / Copywriting',
        image: require('./assets/images/HomeIcons/6.png'),
        id: 18
    },
    {
        title: 'Video Production',
        image: require('./assets/images/HomeIcons/7.png'),
        id: 20
    },
    {
        title: 'Lead Generation',
        image: require("./assets/images/HomeIcons/leadGen.png"),
        id: 17
    }
]


export function userRole() {
    return getUserDetail('roles')
}

export function companyId() {
    return getUserDetail('companyId')
}

export const SECRET_KEY = "testing1234dfkshdkldf"

export function setUserDetail(data) {
    var token = jwt.sign(data, SECRET_KEY);
    localStorage.setItem("token", token)
}

export function getUserDetail(key = false) {
    var token = localStorage.getItem("token")
    try {
        if (token) {
            var decoded = jwt.verify(token, SECRET_KEY);
            if (key) {
                return get(decoded, key, null)
            } else {
                return decoded
            }
        }
    } catch (error) {
        localStorage.clear();
        setInterval(() => {
            window.location.href = window.location.origin;

        }, 1000);

        // window.location.replace(window.location.host);

    }
}

export const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const history = createBrowserHistory()
import React from 'react';
import './assets/css/style.css';
import './assets/css/admin-custom.css';
import './assets/css/sidemenu.css';
import './assets/css/responsive.css';
import { Route, Switch, Router, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { history, getUserDetail } from "./const";
import PrivateRouter from './PrivateRouter/PrivateRouter';
import { getPostAProjectData, clearAllData, clearProjectData } from './redux/action/PostAProject/PostAProject';
import { clearSignupData } from './redux/action/SignUp/SignUpAction';
import { logout } from './redux/action/User/UserAction';
import { Suspense } from 'react';
import ReactGA from "react-ga";
import { Spin } from 'antd';

const Footer = React.lazy(() => import("./layout/Footer/Footer"));
const Header = React.lazy(() => import("./layout/Header/Header"));
const SignUpSeller = React.lazy(() => import('./SignUp/SignUpSeller'));
const AboutUs = React.lazy(() => import('./AboutUs/AboutUs'));
const Faq = React.lazy(() => import('./Faq/Faq'));
const Pricing = React.lazy(() => import('./Pricing/Pricing'));
const TermsOfService = React.lazy(() => import('./TermsOfService.js/TermsOfService'));
const Privacy = React.lazy(() => import('./Privacy/Privacy'));
const GeneralSettings = React.lazy(() => import('./Settings/GeneralSettings'));
const FindBuyer = React.lazy(() => import('./Settings/FindBuyer'));
const Inbox = React.lazy(() => import('./Settings/Inbox'));
const Reviews = React.lazy(() => import('./Settings/Reviews'));
const Notifications = React.lazy(() => import('./Settings/Notifications'));
const Billings = React.lazy(() => import('./Settings/Billings'));
const NotFoundPage = React.lazy(() => import('./NotFoundPage/NotFoundPage'));
const Home = React.lazy(() => import('./Home/Home'));
const Services = React.lazy(() => import('./Settings/Services'));
const MyCompany = React.lazy(() => import('./Settings/MyCompany'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword/ForgotPassword'));
const MyPosts = React.lazy(() => import('./Settings/MyPosts'));
const BuyerHome = React.lazy(() => import('./Home/BuyerHome'));
const FindBuyerDetails = React.lazy(() => import('./Settings/FindBuyerDetails'));
const MyPostsDetails = React.lazy(() => import('./Settings/MyPostsDetails'));
const News = React.lazy(() => import('./News/News'));
const ResetPassword = React.lazy(() => import('./ResetPassword/ResetPassword'));
const NewsDetails = React.lazy(() => import('./News/NewsDetails'));
const AdminHeader = React.lazy(() => import('./AdminPanel/_layouts/AdminHeader/AdminHeader'));
const AdminDashboard = React.lazy(() => import('./AdminPanel/Components/AdminDashboard/AdminDashboard'));
const AdminNews = React.lazy(() => import('./AdminPanel/Components/AdminNews/AdminNews'));
const AdminNewsDetails = React.lazy(() => import('./AdminPanel/Components/AdminNews/AdminNewsDetails'));
const AdminNewsList = React.lazy(() => import('./AdminPanel/Components/AdminNews/AdminNewsList'));
const AdminFooter = React.lazy(() => import('./AdminPanel/_layouts/AdminFooter/AdminFooter'));
const TermsAndConditions = React.lazy(() => import('./TermsOfService.js/TermsAndConditions'));
const AdminSideNav = React.lazy(() => import('./AdminPanel/Components/AdminSideNav/AdminSideNav'));
const ConfirmEmail = React.lazy(() => import('./Login/ConfirmEmail'));
const Cookies = React.lazy(() => import('./Cookies/Cookies'));
const AcceptableUse = React.lazy(() => import('./AcceptableUse/AcceptableUse'));
const SellerHome = React.lazy(() => import('./Home/SellerHome'));
const ContactUs = React.lazy(() => import('./ContactUs/ContactUs'));
const UnderConstruction = React.lazy(() => import('./UnderConstruction/UnderConstruction'));
const Requests = React.lazy(() => import('./Settings/Requests'));
const InboxBuyer = React.lazy(() => import('./Settings/InboxBuyer'));
const FrameAbleLogin = React.lazy(() => import('./Login/FrameAbleLogin'));
const FrameAblePostProject = React.lazy(() => import('./Home/FrameAblePostProject'));
const HowItWorks = React.lazy(() => import('./HowItWorks/HowItWorks'));
const SwitchPostProject = React.lazy(() => import('./PostAProjectPages/SwitchPostProject'));


class App extends React.Component {

  componentWillReceiveProps() {
    ReactGA.initialize('UA-173091947-1', { debug: false });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidMount() {
    if (localStorage.getItem('buyerId') && localStorage.getItem('projectIdDashboard')) {
      this.props.dispatch(getPostAProjectData('current', 0));
      this.props.dispatch(clearSignupData());
      this.props.dispatch(clearAllData());
      this.props.dispatch(clearProjectData());
      this.props.dispatch(logout());
    }
  }

  render() {

    var href = window.location.href;
    const route = href.match(/([^\\/]*)\/*$/)[1];
    var role = getUserDetail('roles')


    return (
      <section>
        <Suspense fallback={<div className="component_loader"> <Spin size="large" /></div>}>
          <Router history={history}>
            <div className={this.props.class && this.props.class.class === true ? "main_section active" : "main_section"}>
              <div className={route.includes('admin') ? "page" : ''}>
                <div className={route.includes('admin') ? "page-main" : ''}>
                  {route.includes('admin') ? <AdminHeader /> : <Header />}
                  <div>
                    {route.includes('admin') ? <AdminSideNav /> : ''}
                    <Switch>
                      <Route exact path="/">
                        {role === "Seller" ?
                          <Redirect to="/agency" />
                          :
                          <Redirect to="/home" />
                        }
                      </Route>
                      {/* Normal Routes */}
                      <Route path="/home" exact component={Home} />
                      <Route path="/faq" exact component={Faq} />
                      <Route path="/pricing" exact component={Pricing} />
                      <Route path="/terms-of-service" exact component={TermsOfService} />
                      <Route path="/terms-of-conditions" exact component={TermsAndConditions} />
                      <Route path="/privacy-policy" exact component={Privacy} />
                      <Route path="/cookie-policy" exact component={Cookies} />
                      <Route path="/acceptable-use" exact component={AcceptableUse} />
                      <Route path="/how-it-works" exact component={HowItWorks} />
                      <Route path="/about" exact component={AboutUs} />

                      <Route path="/sign-up/(agency|client)" exact component={SignUpSeller} />
                      <Route path="/news" exact component={News} />
                      <Route path="/news-details/:id" exact component={NewsDetails} />
                      <Route path="/dashboard-client/:buyerId/:projectId" exact component={BuyerHome} />
                      <Route path="/under-construction" component={UnderConstruction} />
                      <Route path="/forgot-password" exact component={ForgotPassword} />
                      <Route path="/resetpassword/:email/:token" exact component={ResetPassword} />
                      <Route path="/confirm/:id/:token" exact component={ConfirmEmail} />
                      <Route path="/contact" exact component={ContactUs} />

                      {/* FrameAble Routes */}
                      <Route path="/login" exact component={FrameAbleLogin} />
                      <Route path="/post-project" exact component={FrameAblePostProject} />
                      <Route path="/post-a-project" exact component={SwitchPostProject} />

                      {/* Private Routes */}
                      <PrivateRouter path="/general-setting" exact component={GeneralSettings} />
                      <PrivateRouter path="/my-company" exact component={MyCompany} />
                      <PrivateRouter path="/service-setting" exact component={Services} />
                      <PrivateRouter path="/find-client" exact component={FindBuyer} />
                      <PrivateRouter path="/find-client-details" exact component={FindBuyerDetails} />
                      <PrivateRouter path="/billing" exact component={Billings} />
                      <PrivateRouter path="/inbox/:projectId?" exact component={Inbox} />
                      <PrivateRouter path="/client-inbox/:projectId?/:sellerId?" exact component={InboxBuyer} />
                      <PrivateRouter path="/reviews" exact component={Reviews} />
                      <PrivateRouter path="/notifications" exact component={Notifications} />
                      <PrivateRouter path="/posts" exact component={MyPosts} />
                      <PrivateRouter path="/posts-details/:projectId" exact component={MyPostsDetails} />
                      <PrivateRouter path="/agency" exact component={SellerHome} />
                      <PrivateRouter path="/requests" exact component={Requests} />

                      {/* Admin Routes */}
                      <Route exact path="/admin-dashboard" component={AdminDashboard} />
                      <Route exact path="/admin-news" component={AdminNews} />
                      <Route exact path="/admin-news-list" component={AdminNewsList} />
                      <Route exact path="/admin-news-details/:id" component={AdminNewsDetails} />

                      {/* Not Found Page */}
                      <Route component={NotFoundPage} />
                    </Switch>
                  </div>
                </div>
              </div>
              {route.includes('admin') ? <AdminFooter /> : <Footer />}
            </div>
          </Router>

        </Suspense>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    class: state.postaproject.newPostProject
  };
};

export default withRouter(connect(mapStateToProps)(App));
import { getUserDetail } from "../../const";
import {
    GET_POST_A_PROECT_QUESTIONS_ERROR,
    GET_POST_A_PROECT_QUESTIONS_SUCCESS,
    CLEAR_ALL_DATA,
    SEND_PROJECT_ID_TO_DASHBOARD,
    CLEAR_PROJECT_DATA
} from "../action/PostAProject/PostAProject";
var newPostProject = localStorage.getItem('newPostProject') ?
    JSON.parse(localStorage.getItem('newPostProject')) : { selectedQuestion: 0 };


    
const initialState = {
    postAProject: [],
    projectId: '',
    posttitle: '',
    uploadLink: [],
    current: 0,
    newPostProject: newPostProject
};

const postAProjectMap = {
    [GET_POST_A_PROECT_QUESTIONS_SUCCESS]: (state, action) => {
        let newPostProject = { ...state.newPostProject };
        newPostProject[action.key] = action.payload;
        localStorage.setItem('newPostProject', JSON.stringify(newPostProject))
        return {
            ...state,
            newPostProject,
        }
    },
    [GET_POST_A_PROECT_QUESTIONS_ERROR]: (state, action) => {
        return {
            ...state,
            postAProject: action.payload
        }
    },
    [SEND_PROJECT_ID_TO_DASHBOARD]: (state, action) => {
        return {
            ...state,
            projectId: action.payload
        }
    },
    [CLEAR_ALL_DATA]: (state, action) => {
        return {
            ...state,
            id: null,
            businessType: 2,
            companyType: getUserDetail('roles') === 'Buyer' ? 1 : 2,
            companyEmail: null,
            companyName: '',
            companyContactNumber: '',
            confirmPassword: '',
            companySize: '',
            operationScale: '',
            tagline: '',
            description: '',
            logoPath: null,
            tradingAs: '',
            distance: 50,
            latitude: '',
            longtitude: '',
            location: '',
            title: '',
            categoryTag: null,
            forfetch: '',
            current: 0,
            steps: null,
            trustpilotConnectedUrl: '',
            glassdoorConnnectedUrl: '',
            connectFacebookBusinessUrl: '',
            active: false,
            projectId: '',
            buyerTemplate: '',
            sellerTemplate: '',
            industryId: null,
            industryName: '',
            companyUserId: 0,
            jobTitle: '',
            userDto: {
                id: '',
                foreName: '',
                surName: '',
                phoneNumber: '',
                email: '',
                password: '',
                photoPath: null,
            }
        }
    },
    [CLEAR_PROJECT_DATA]: (state, action) => {
        localStorage.removeItem('newPostProject');
        return {
            ...state,
            newPostProject: null
        }
    }
}

export default function reducer(state = initialState, action = {}) {
    const fn = postAProjectMap[action.type];
    return fn ? fn(state, action) : state;
}